.about-section {
  background-color: #f0f0f0;
  padding: 80px 20px;
  text-align: center;
}

.about-content {
  max-width: 1000px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

/* Stylizacja sekcji zespołu */
.about-team {
  display: flex;
  justify-content: center;
  gap: 50px; /* Odstępy między pracownikami */
  flex-wrap: wrap; /* Wymuszanie układu w wierszu */
  margin-bottom: 40px; /* Odstęp od sekcji poniżej */
}

.team-member {
  text-align: center;
  flex: 1; /* Każdy element zajmuje równą przestrzeń */
  min-width: 200px; /* Minimalna szerokość, aby elementy były responsywne */
  max-width: 300px; /* Maksymalna szerokość elementów */
  background-color: #ffffff; /* Białe tło dla sekcji pracownika */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Dodanie delikatnego cienia */
}

.user-icon {
  font-size: 100px; /* Większa ikona zastępcza */
  color: #827e7e;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-top: 10px;
  color: black;
}

.team-member p {
  font-size: 1rem;
  margin: 5px 0;
  color: black;
}

.about-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.about-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-text {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.6;
  max-width: 800px;
}

.about-text p {
  margin-bottom: 20px;
}

.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #063e4d;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #044654;
}
