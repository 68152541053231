/* components/ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
  }
  
  .scroll-to-top-icon {
    font-size: 2rem;
    color: #00506b;
    background-color: #ffffff;
    border: 2px solid #00506b;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .scroll-to-top-icon:hover {
    background-color: #00506b;
    color: #ffffff;
  }
  